
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useRoute, useRouter } from "vue-router";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const token = ref(route.params.token);
    const submitButton = ref<HTMLButtonElement | null>(null);
    let errorsRef = ref(null);

    const registration = Yup.object().shape({
      email: Yup.string().required().email().label("Email"),
      password: Yup.string().min(8).required().label("Password"),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    function getError(error) {
      errorsRef.value = error;
    }

    function success(message) {
      Swal.fire({
        text: message,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        // Go to page after successfully login
        router.push({ name: "home" });
      });
    }

    function tokenError(error) {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Try again!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      }).then(function () {
        // Go to page after successfully login
        router.push({ name: "password-reset" });
      });
    }

    const onSubmitRegister = async (values) => {
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButton.value || count > 0) {
        return;
      }
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      // Send login request
      ApiService.post("change_password", values)
        .then(({ data }) => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
          success(data.message);
        })
        .catch(function (error) {
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
          if (error.response && error.response.status == 422) {
            if (error.response.data.errors?.token) {
              tokenError(error.response.data.errors?.token);
            } else if (error.response.data.errors == null) {
              tokenError("Something went wrong");
            } else {
              getError(error.response?.data.errors);
            }
          }
        });
    };
    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };
    return {
      registration,
      onSubmitRegister,
      submitButton,
      token,
      errorsRef,
      hideError,
    };
  },
});
